@import url("https://fonts.cdnfonts.com/css/helvetica-neue-5");

:root {
    --colorEbonyClay: #1f1f45;
    --colorYellow: #eeffaa;
    --colorOrange: #ff6611;
    --colorOrangeTwo: #F57133;
    --colorOrangeThree: #F47B4D;
}

body {
    font-family: "Helvetica Neue", sans-serif;
    margin: 0;
    color: var(--colorEbonyClay);
}

h1, h2, h3 {
    font-weight: 700;
}

.btn {
    &-orange {
        color: #ffffff;
        font-size: 18px;
        font-weight: 700;
        height: 59px;
        line-height: 53px;
        background-color: var(--colorOrangeTwo);
        border: 3px solid var(--colorEbonyClay);
        border-radius: 20px;
        display: flex;
        gap: 45px;
        padding: 0 25px;
        align-items: center;
        box-shadow: 0 4px 0 0 var(--colorEbonyClay);

        &.small {
            gap: 12px;
            height: 36px;
            line-height: 32px;
            font-size: 14px;
            padding: 0 14px;
            border-radius: 50px;
            border: 2px solid var(--colorEbonyClay);
        }
    }

    &-primary {
        height: 80px;
        color: var(--colorEbonyClay);
        display: flex;
        align-items: center;
        box-shadow: 0 4px 0 0 var(--colorEbonyClay);
        padding: 0 29px;
        gap: 10px;
        background-color: var(--colorYellow);
        border: 3px solid var(--colorEbonyClay);
        border-radius: 20px;
        font-weight: 700;
        font-size: 18px;
    }
}

.avatar-frame {
    display: flex;
    align-items: center;
    gap: 19px;

    &__img-wrapper {
        position: relative;
        width: 67px;
        height: 71px;
        background-color: var(--colorYellow);
        border: 3px solid var(--colorEbonyClay);
        box-shadow: 0 4px 0 0 var(--colorEbonyClay);
        border-radius: 200px;
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__info-group {
        display: flex;
        flex-direction: column;
        gap: 5px;

        &__name {
            font-weight: 700;
            font-size: 18px;
            line-height: 20px;
        }

        &__position {
            font-size: 16px;
            line-height: 18px;
        }
    }
}

.highlight-text {
    &-yellow {
        background-color: var(--colorYellow);
        border-radius: 24px;
    }

    &-orange {
        background-color: var(--colorOrangeTwo);
        border-radius: 24px;
    }
}

@media screen and (min-width: 1px) {
    .btn {
        &-primary {
            height: 55px;
            font-size: 14px;
            gap: 5px;
            padding: 0 12px;

            svg {
                width: 22px;
                height: 23px;
            }
        }
    }
}

@media screen and (min-width: 500px) {
    .btn {
        &-primary {
            height: 63px;
            font-size: 14px;
            gap: 10px;
            padding: 0 23px;

            svg {
                width: 22px;
                height: 23px;
            }
        }
    }
}

@media screen and (min-width: 1200px) {
    .btn {
        &-primary {
            height: 63px;
            font-size: 14px;
            gap: 10px;
            padding: 0 23px;

            svg {
                width: 22px;
                height: 23px;
            }
        }
    }
}

@media screen and (min-width: 1400px) {
    .btn {
        &-primary {
            height: 74px;
            padding: 0 27px;
            gap: 10px;
            font-size: 16px;

            svg {
                width: 26px;
                height: 27px;
            }
        }
    }
}

@media screen and (min-width: 1500px) {
    .container {
        width: 100%;
        max-width: 1418px;
        padding: 0 15px;
        margin: 0 auto;
        position: relative;
    }

    .btn {
        &-primary {
            height: 80px;
            padding: 0 29px;
            gap: 10px;
            font-size: 18px;

            svg {
                width: 28px;
                height: 29px;
            }
        }
    }
}
